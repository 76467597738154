import { NexusIconsProps } from "../interfaces/NexusIconsProps";

export const IconMessage = ({ color = "white" }: NexusIconsProps) => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x={2.5} y={4.5} width={15} height={11} rx={0.5} stroke={color} />
      <path d="M3 5l6.382 5.015a1 1 0 001.236 0L17 5" stroke={color} />
    </svg>
  );
};
