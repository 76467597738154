export interface IAnimationElements {
  visible: boolean;
  classChange: string;
}

export class AnimationElements implements IAnimationElements {
  visible: boolean;
  classChange: string;
  constructor({ visible, classChange }: IAnimationElements) {
    this.visible = visible;
    this.classChange = classChange;
  }
}
