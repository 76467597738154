import {
  DeleteParams,
  EndpointParams,
  GetParams,
  PatchParams,
  PostParams,
} from "./entity/EndpointEntity";
import axios, { AxiosError, AxiosInstance } from "axios";
import { NetworkResponse } from "./entity/NetworkResponse";
import {
  NetworkError,
  NetworkInternalServerError,
  NetworkResponseError,
  NetworkUnauthorizedError,
} from "./errors/NetworkError";
import { CredentialEntity } from "web/features/auth/domain/entities/CredentialEntity";

export interface NetworkAdapter {
  get<T>(params: GetParams): Promise<NetworkResponse<T> | NetworkError>;
  post<T>(params: PostParams): Promise<NetworkResponse<T> | NetworkError>;
  patch<T>(params: PatchParams): Promise<NetworkResponse<T> | NetworkError>;
  delete<T>(params: DeleteParams): Promise<NetworkResponse<T> | NetworkError>;
}

export type NetworkAdapterParams = {
  baseURL?: string;
};

export class NetworkAdapterImpl implements NetworkAdapter {
  instance: AxiosInstance;

  constructor() {
    this.instance = axios.create({
      baseURL: process.env.REACT_APP_API_BASE_URL,
    });
  }

  async get<T>({ path, headers, params, needToken }: GetParams) {
    return this.performRequest<T>({
      path,
      headers: headers,
      params,
      method: "GET",
    });
  }

  async post<T>({ path, headers, params, body, needToken }: PostParams) {
    return this.performRequest<T>({
      path,
      headers: headers,
      params,
      body,
      method: "POST",
      needToken,
    });
  }

  async patch<T>({ path, headers, params, body, needToken }: PatchParams) {
    return this.performRequest<T>({
      path,
      headers,
      params,
      body,
      method: "PATCH",
      needToken,
    });
  }

  async delete<T>({ path, headers, params, needToken }: DeleteParams) {
    return this.performRequest<T>({
      path,
      headers,
      params,
      method: "DELETE",
      needToken,
    });
  }

  private async performRequest<T>({
    path,
    headers,
    params,
    body,
    method,
    needToken = true,
  }: EndpointParams): Promise<NetworkResponse<T> | NetworkError> {
    try {
      const defaultHeaders: Record<string, string> = {};
      if (needToken) {
        const localCredential = localStorage.getItem("credential") || sessionStorage.getItem("credential");
        if (localCredential) {
          const credential = CredentialEntity.fromJson(
            JSON.parse(localCredential)
          );
          defaultHeaders["Authorization"] = `Bearer ${credential.access_token}`;
        }
      }

      const axiosReponse = await this.instance.request<NetworkResponse<T>>({
        url: path,
        method,
        headers: {
          ...defaultHeaders,
          ...headers,
        },
        params,
        data: body,
      });
      return {
        status: axiosReponse.status,
        data: axiosReponse.data,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.status === 500) return new NetworkInternalServerError();
        if (error.status === 401) return new NetworkUnauthorizedError();
        return new NetworkResponseError({
          message: error.message,
          statusCode: error.response?.status,
          body: error.response?.data,
        });
      }
      throw error;
    }
  }
}