import { NxSVG } from "../../../../common/components/NxSvg";
import { IconAdd } from "../../../../common/fonts/icons/components/IconAdd";
import { IconImage } from "../../../../common/fonts/icons/components/IconImage";
import { IconMore } from "../../../../common/fonts/icons/components/IconMore";
import { CSName } from "../../../../common/utils/ClassName";

export interface ProjectEntity {
  title: string;
  description: string;
  image?: string;
}

interface HomeCardProjectProps {
  project?: ProjectEntity;
  onProjectClick: () => void;
}

interface ImageCardProps {
  project?: ProjectEntity;
}
const ImageCard = ({ project }: ImageCardProps) => {
  if (!project) {
    return (
      <div className="m-auto flex flex-col">
        <div className="rounded-full p-3 bg-nx-main-600 cursor-pointer mb-2 self-center">
          <NxSVG className="fill-white" width={22} height={22}>
            <IconAdd />
          </NxSVG>
        </div>
        <h5 className="text-xs font-medium text-nx-gray-600 dark:text-nx-white">
          Criar novo
        </h5>
      </div>
    );
  }
  if (!project.image) {
    return (
      <NxSVG className="w-8 h-8 fill-nx-gray-600 m-auto dark:fill-nx-white ">
        <IconImage />
      </NxSVG>
    );
  }
  return (
    <img src={project.image} alt="" className="w-full h-full object-cover " />
  );
};

export const HomeCardProject = ({
  onProjectClick,
  project,
}: HomeCardProjectProps) => {
  return (
    <div
      className="flex flex-row flex-wrap items-start gap-6 mt-6 cursor-pointer"
      onClick={onProjectClick}
    >
      <div className="w-80 flex flex-col overflow-x justify-end">
        <div
          className="flex w-full aspect-[331/195]
          rounded-lg border-[0.5px] border-nx-gray-200 dart:border-nx-gray-500 overflow-hidden "
        >
          <ImageCard project={project} />
        </div>

        <div
          className={CSName("flex flex-row justify-between mt-1")
            .concatIf(project !== undefined, "visible", "invisible")
            .build()}
        >
          <div className="flex flex-col ">
            <h6 className="text-sm text-gray-600 font-medium dark:text-white">
              {project?.title}
            </h6>
            <h6 className="text-start text-caption text-gray-400 font-normal">
              {project?.description}
            </h6>
          </div>
          <NxSVG
            className="cursor-pointer fill-nx-gray-600 dark:fill-nx-white w-5 h-5"
            onClick={() => {}}
          >
            <IconMore />
          </NxSVG>
        </div>
      </div>
    </div>
  );
};
