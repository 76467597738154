import { createOrgUsecase } from "web/features/auth/domain/usecase/CreateOrgUsecase";
import { CreateOrgParams } from "web/features/auth/domain/params/CreateOrgParams";
import { useState } from "react";
abstract class CreateOrgState {}

class CreateOrgLoading extends CreateOrgState {}

class CreateOrgSuccess extends CreateOrgState {}

class CreateOrgFailure extends CreateOrgState {}

export const useCreateOrgHook = () => {
  const [orgState, setState] = useState<CreateOrgState>();
  return {
    orgState: {
      isLoading: orgState instanceof CreateOrgLoading,
      isSuccess: orgState instanceof CreateOrgSuccess,
      isError: orgState instanceof CreateOrgFailure,
    },
    createOrg: async (params: CreateOrgParams) => {
      setState(new CreateOrgLoading());
      const result = await createOrgUsecase(params);
      if (result instanceof Error) {
        return setState(new CreateOrgFailure());
      }
      setState(new CreateOrgSuccess());
    },
  };
};
