import { useEffect, useRef, useState } from "react";
import { HomeNavFooterMenu } from "./HomeNavFooterMenu";
import { useHomeUsecase } from "../hooks/UseHomeUsecase";

export const HomeNavFooter = () => {
  const ref = useRef<HTMLUListElement>(null);
  const [name, setName] = useState("Nome_Sobrenome");
  const usecase = useHomeUsecase();

  useEffect(() => {
    // This will set the use name if it wasn't already sey
    if (name === "Nome_Sobrenome")
      usecase.getUser().then((u) => {
        setName(u.name ?? "Nome_Sobrenome");
      });

    const handleClickOutside = (e: MouseEvent) => {
      let parent = e.target as Node;
      while (parent) {
        parent = parent.parentNode as Node;
        if (parent === ref.current) return;
      }
      ref.current?.classList.remove("visible");
      ref.current?.classList.add("invisible");
      ref.current?.classList.remove("opacity-100");
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [name, usecase]);

  return (
    <div className="relative">
      <button
        className="group/menu  w-full flex flex-row min-h-16 px-6 py-4 gap-2 items-center cursor-pointer
      bg-nx-gray-100 dark:bg-nx-dark-1300"
        onClick={() => {
          if (ref.current) {
            ref.current.classList.remove("invisible");
            ref.current.classList.add("visible");
            ref.current.classList.add("opacity-100");
          }
        }}
      >
        <div className="w-8 h-8 rounded-full border-2	border-nx-main-600 bg-nx-main-600/10">
          <span className="text-xs text-nx-main-600 font-normal">MR</span>
        </div>
        <div className="flex flex-col items-start gap-0.5">
          <div className="text-xs text-nx-main-700 dark:text-nx-white font-medium">
            {name}
          </div>
          <div className="text-[0.625rem] text-nx-gray-400 font-normal">
            Nome_Org • Admin
          </div>
        </div>
        <HomeNavFooterMenu ref={ref} />
      </button>
    </div>
  );
};
