import { NexusIconsProps } from "../interfaces/NexusIconsProps";

export const IconArrowLeft = ({ color = "white" }: NexusIconsProps) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 16L2 10M2 10L8 4M2 10H18"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
