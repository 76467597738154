import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSession } from "./hook/UseSession";

export const SessionWrapper = (props: any): React.JSX.Element => {
  const { session, state } = useSession();
  const navigate = useNavigate();

  useEffect(() => {
    if (state === undefined) {
      session();
    }

    const navigateIfNotLoggedIn = () => {
      if (!window.location.href.includes("/auth")) {
        navigate("/auth/login");
      }
    };

    const navigateIfLoggedIn = () => {
      if (window.location.href.includes("/auth")) {
        navigate("/home");
      }
    };

    if (state !== undefined) {
      if (state) navigateIfLoggedIn();
      if (!state) navigateIfNotLoggedIn();
    }
  }, [state, navigate, session]);

  return <div>{props.children}</div>;
};
