import { NexusIconsProps } from "../interfaces/NexusIconsProps";

export const IconError = ({ color }: NexusIconsProps) => (
  <svg
    viewBox="0 0 16 16"
    fill="fill"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      stroke={color}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.4 8.00001C14.4 11.5346 11.5346 14.4 7.99998 14.4C4.46535 14.4 1.59998 11.5346 1.59998 8.00001C1.59998 4.46538 4.46535 1.60001 7.99998 1.60001C11.5346 1.60001 14.4 4.46538 14.4 8.00001ZM10.895 5.10503C11.1683 5.37839 11.1683 5.82161 10.895 6.09498L8.99001 7.99993L10.8951 9.90504C11.1685 10.1784 11.1685 10.6216 10.8951 10.895C10.6218 11.1684 10.1785 11.1684 9.90517 10.895L8.00006 8.98988L6.09496 10.895C5.8216 11.1683 5.37838 11.1683 5.10502 10.895C4.83165 10.6216 4.83165 10.1784 5.10502 9.90503L7.01012 7.99993L5.10517 6.09498C4.8318 5.82161 4.8318 5.3784 5.10517 5.10503C5.37853 4.83166 5.82175 4.83166 6.09511 5.10503L8.00006 7.00998L9.90502 5.10503C10.1784 4.83166 10.6216 4.83166 10.895 5.10503Z"
      fill="white"
    />
  </svg>
);
