export const IconMore = () => {
  return (
    <svg
      className="block m-auto"
      viewBox="0 0 21 21"
      fill="fill"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10.3612" cy="5.50086" r="1.53846" />
      <circle cx="10.3612" cy="16.2701" r="1.53846" />
      <circle cx="10.3612" cy="10.8854" r="1.53846" />
    </svg>
  );
};
