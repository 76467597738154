import ManagerImages from "../../../../common/fonts/ManagerImages";
import { IconHomeFilled } from "../../../../common/fonts/icons/components/IconHomeFilled";
import { useVisualContext } from "../../../../core/context/VisualContext";
import { IconHelpFilled } from "../../../../common/fonts/icons/components/IconHelpFilled";
import { IconCreditCardFilled } from "../../../../common/fonts/icons/components/IconCreditCardFilled";
import { IconConfig } from "../../../../common/fonts/icons/components/IconConfig";
import { IconUsers } from "../../../../common/fonts/icons/components/IconUsers";
import { HomeNavBarOption, HomeNavSectionTitle } from "./HomeNavBarOption";
import { HomeNavFooter } from "./HomeNavFooter";
import { IconAdd } from "../../../../common/fonts/icons/components/IconAdd";
import { useLocation } from "react-router-dom";

export const HomeNavBar = () => {
  const { isDarkMode } = useVisualContext();
  const location = useLocation();

  const isSelected = (route: string) => {
    const parts = location.pathname.split("/").slice(1);
    return `/${parts.join("/")}` === route;
  };

  return (
    <div
      className="flex flex-col  items-stretch w-64 h-screen 
    bg-nx-gray-50 dark:bg-nx-dark-1200
    "
    >
      <img
        src={isDarkMode ? ManagerImages.darkNexus : ManagerImages.lightNexus}
        className="w-24 mx-6 my-8"
        alt=""
      />
      <HomeNavBarOption
        title="Tela Inicial"
        route="/home"
        icon={<IconHomeFilled />}
        isSelected={isSelected("/home")}
      />
      <HomeNavSectionTitle>PROJETOS</HomeNavSectionTitle>
      <HomeNavBarOption
        title={
          "Previsão produtividade Soja... dasdsadasdasdsadasddsadasdasddasdasdasd"
        }
        isSvg={false}
        icon={<div className="w-3 h-3 bg-[#FF3370] rounded-[2.5px]" />}
        route="/projects/2"
        isSelected={isSelected("/projects/2")}
      />
      <HomeNavBarOption
        title={"Novo projeto"}
        icon={<IconAdd />}
        route="/projects"
        isSelected={isSelected("/projects")}
      />
      <div className="my-2 h-[0.5px] w-full bg-nx-gray-100  dark:bg-nx-dark-1000" />
      <HomeNavSectionTitle>GERAL</HomeNavSectionTitle>
      <HomeNavBarOption
        title="Gerenciar time"
        route="/members"
        icon={<IconUsers />}
        isSelected={isSelected("/members")}
      />
      <HomeNavBarOption
        title="Configurações"
        icon={<IconConfig />}
        route="/settings"
        isSelected={isSelected("/settings")}
      />
      <HomeNavBarOption
        title="Assinatura"
        route="/signatures"
        icon={<IconCreditCardFilled />}
        isSelected={isSelected("/signatures")}
      />
      <HomeNavBarOption
        title="Suporte"
        route="/support"
        icon={<IconHelpFilled />}
        isSelected={isSelected("/support")}
      />
      <div className="h-full"></div>
      <HomeNavFooter />
    </div>
  );
};
