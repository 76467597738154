export const MemberUserCell = () => {
  return (
    <div className="flex flex-row gap-2 py-4 ml-6 cursor-pointer">
      <div className="rounded-full w-6 h-6 overflow-hidden">
        <img
          alt=""
          src="https://thispersondoesnotexist.com"
          className="w-full h-full object-cover"
        />
      </div>
      <div className="flex flex-col items-start">
        <h6 className="text-xs text-nx-gray-700 dark:text-nx-white">
          Marcela Ribeiro
        </h6>
        <h6 className="text-caption font-normal text-nx-gray-400 dark:text-nx-gray-200">
          Marcela Ribeiro
        </h6>
      </div>
    </div>
  );
};
