import { LabelStyles } from "../../fonts/FontStyles";
import { Color } from "../../fonts/Colors";
import React, { useState } from "react";
import { GlobalError, UseFormRegisterReturn } from "react-hook-form/dist/types";
import { useVisualContext } from "../../../core/context/VisualContext";

export interface INxFormFieldProps {
  labelText?: string;
  prefix?: JSX.Element;
  register?: UseFormRegisterReturn;
  type?: string;
  error?: GlobalError;
  placeholder?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  disabled?: boolean,
}

export const NxFormField = ({
  prefix,
  labelText,
  register,
  type = "text",
  placeholder,
  error,
  onChange,
  value,
  disabled = false,
}: INxFormFieldProps) => {
  const { isDarkMode } = useVisualContext();
  const [isFocused, setFocus] = useState(false);

  const hasError = (): boolean => {
    return error !== undefined;
  };

  const getLabelColor = () => {
    if (hasError()) {
      return Color.danger600;
    }
    return isDarkMode ? Color.white : Color.gray600;
  };

  const getBorderColor = () => {
    if (hasError()) {
      return Color.danger600;
    }

    return isFocused ? Color.main600 : Color.gray400;
  };

  const getBackgroundColor = () => {
    if (isFocused) {
      return isDarkMode ? Color.darkFocus : Color.lightFocus;
    }
    return "rgba(202, 230, 255, 0.1)";
  };

  return (
    <div className="flex flex-col">
      <label
        className="transition"
        style={{
          ...LabelStyles.caption,
          textAlign: "left",
          marginBottom: 4,
          color: isFocused ? Color.main600 : getLabelColor(),
        }}
      >
        {labelText}
      </label>
      <div
        className="flex flex-row items-center gap-2 py-3 px-4 h-10 rounded transition"
        style={{
          backgroundColor: getBackgroundColor(),
          borderWidth: hasError() ? 1 : 0.5,
          borderColor: getBorderColor(),
          borderStyle: "solid",
        }}
      >
        {prefix && (
          <div
            style={{
              width: 20,
              height: 20,
            }}
          >
            {prefix}
          </div>
        )}

        <input
          className="w-full outline-none bg-transparent text-xs dark:text-nx-white placeholder-nx-gray-200 dark:placeholder-nx-gray-500
          webkit-black dark:webkit-white"
          {...register}
          onChange={(e) => {
            if (onChange) {
              onChange(e);
            }
            register?.onChange(e);
          }}
          value={value}
          type={type}
          placeholder={placeholder}
          onFocus={(e) => {
            setFocus(true);
          }}
          onBlur={(e) => {
            setFocus(false);
          }}
          disabled={disabled}
        />
      </div>
      {error && (
        <span
          style={{
            ...LabelStyles.caption,
            marginTop: 4,
            color: Color.danger600,
            textAlign: "left",
          }}
        >
          {error.message}
        </span>
      )}
    </div>
  );
};
