import { NexusFailure } from "../../../domain/errors/NexusFailure";

export abstract class NetworkError extends NexusFailure {}

export class NetworkInternalServerError extends NetworkError {}

export class NetworkUnauthorizedError extends NetworkError {}

type NetworkResponseErrorParams = {
  statusCode?: number;
  message: string;
  body: any;
};

export class NetworkResponseError extends NetworkError {
  readonly statusCode?: number;
  readonly message: string;
  readonly body: any;

  constructor({ statusCode, message, body }: NetworkResponseErrorParams) {
    super();
    this.statusCode = statusCode;
    this.message = message;
    this.body = body;
  }
}
