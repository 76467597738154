import { useState } from "react";

interface Elements {
    className?:string,
    isChecked?:boolean,
    onClick:(isChecked:boolean)=>void,
}


export function CheckBoxField({className, onClick, isChecked}:Elements){
    const [checked, setCheck] = useState(false);
    return (
        <label className={"block relative pl-5 h-5 "} onClick={()=>{
            setCheck(!checked);
            onClick(!checked);
        }}>
            <span className={"absolute h-5 w-5 left-0 top-0 rounded-sm "+className}></span>
            <input type="checkbox" className="absolute left-0 top-0 opacity-0 cursor-pointer h-5 w-5 checked:opacity-100 accent-nx-main-600" checked={isChecked ?? checked}/>
        </label>
    );
}