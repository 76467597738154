export const IconDelete = () => {
  return (
    <svg viewBox="0 0 12 16" fill="fill" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.39143 0.29L9 1H11.1429C11.6143 1 12 1.45 12 2C12 2.55 11.6143 3 11.1429 3H0.857143C0.385714 3 0 2.55 0 2C0 1.45 0.385714 1 0.857143 1H3L3.60857 0.29C3.76286 0.11 3.98571 0 4.20857 0H7.79143C8.01429 0 8.23714 0.11 8.39143 0.29ZM2.66667 16C1.75 16 1 15.2286 1 14.2857V5.71429C1 4.77143 1.75 4 2.66667 4H9.33333C10.25 4 11 4.77143 11 5.71429V14.2857C11 15.2286 10.25 16 9.33333 16H2.66667Z"
      />
    </svg>
  );
};
