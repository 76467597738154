import { NexusIconsProps } from "../interfaces/NexusIconsProps";

export const IconWarning = ({ color }: NexusIconsProps) => (
  <svg
    viewBox="0 0 16 16"
    fill="fill"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      stroke={color}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.4 8.00001C14.4 11.5346 11.5346 14.4 7.99998 14.4C4.46535 14.4 1.59998 11.5346 1.59998 8.00001C1.59998 4.46538 4.46535 1.60001 7.99998 1.60001C11.5346 1.60001 14.4 4.46538 14.4 8.00001ZM8.00007 4.1C8.38667 4.1 8.70007 4.4134 8.70007 4.8V8C8.70007 8.3866 8.38667 8.7 8.00007 8.7C7.61347 8.7 7.30007 8.3866 7.30007 8V4.8C7.30007 4.4134 7.61347 4.1 8.00007 4.1ZM8.00009 11.2C8.44192 11.2 8.80009 10.8418 8.80009 10.4C8.80009 9.95818 8.44192 9.6 8.00009 9.6C7.55826 9.6 7.20009 9.95818 7.20009 10.4C7.20009 10.8418 7.55826 11.2 8.00009 11.2Z"
      fill="white"
    />
  </svg>
);
