import { NexusIconsProps } from "../interfaces/NexusIconsProps";

export const IconHomeFilled = ({ color }: NexusIconsProps) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="fill"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 9.45377C2 9.1654 2.12448 8.89108 2.3415 8.70119L9.3415 2.57619C9.71852 2.24629 10.2815 2.24629 10.6585 2.57619L17.6585 8.70119C17.8755 8.89108 18 9.16541 18 9.45377V17C18 17.5523 17.5523 18 17 18H13C12.4477 18 12 17.5523 12 17V13H8V17C8 17.5523 7.55228 18 7 18H3C2.44772 18 2 17.5523 2 17V9.45377Z"
        fill={color}
      />
    </svg>
  );
};
