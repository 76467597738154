import { forwardRef, useEffect, useRef } from "react";
import { NxSVG } from "../../../../common/components/NxSvg";
import { IconDropSelect } from "../../../../common/fonts/icons/components/IconDropSelect";
import { CSName } from "../../../../common/utils/ClassName";
import { useVisualContext } from "../../../../core/context/VisualContext";
import { useHomeUsecase } from "../hooks/UseHomeUsecase";
import { useNavigate } from "react-router-dom";

export const HomeNavFooterMenu = forwardRef<HTMLUListElement>((props, ref) => {
  const { setMode, isDarkMode } = useVisualContext();
  const home = useHomeUsecase();
  const refSubmenu = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      let parent = e.target as Node;
      while (parent) {
        parent = parent.parentNode as Node;
        if (parent === refSubmenu.current) return;
      }
      refSubmenu.current?.classList.remove("visible");
      refSubmenu.current?.classList.add("invisible");
      refSubmenu.current?.classList.remove("opacity-100");
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <ul
      ref={ref}
      className="invisible opacity-0 flex flex-col gap-2 absolute bottom-[80%] w-32 p-2 rounded shadow-md transition
      bg-nx-white dark:bg-nx-dark-1200 dark:shadow-nx-white/10
      "
    >
      <li
        onClick={() => {
          if (refSubmenu.current) {
            refSubmenu.current.classList.remove("invisible");
            refSubmenu.current.classList.add("visible");
            refSubmenu.current.classList.add("opacity-100");
          }
        }}
        className="group/change-theme relative flex flex-row items-center justify-between text-xs w-full h-8 rounded text-start px-2  
          text-nx-gray-600 dark:nx-text-white 
          hover:bg-nx-main-600 hover:text-nx-white focus:bg-nx-main-600 focus:text-nx-white transition dark:text-nx-white
          "
      >
        Alterar tema
        <NxSVG
          className="fill-nx-gray-600 group-hover/change-theme:fill-nx-white dark:fill-nx-white transition"
          width={16}
          height={16}
        >
          <IconDropSelect />
        </NxSVG>
        <div
          ref={refSubmenu}
          className="opacity-0 invisible flex flex-col gap-2 absolute  ml-2 left-full -bottom-2 w-[6.75rem] p-2 rounded shadow-md transition
          bg-nx-white dark:bg-nx-dark-1200  dark:shadow-nx-white/10"
        >
          <div
            className={CSName(
              `flex flex-col justify-center text-xs w-full h-8 rounded text-start px-2 transitionhover:bg-nx-main-600 hover:bg-nx-main-600
              text-nx-gray-600 dark:text-nx-white hover:text-nx-white`
            )
              .concatIf(
                !isDarkMode,
                "bg-nx-main-600 text-nx-white",
                "text-nx-gray-600"
              )
              .build()}
            onClick={() => setMode(false)}
          >
            Tema claro
          </div>
          <div
            className={CSName(
              `flex flex-col justify-center text-xs w-full h-8 rounded text-start px-2 transition hover:bg-nx-main-600 text-nx-gray-600 dark:text-nx-white hover:text-nx-white`
            )
              .concatIf(
                isDarkMode,
                "bg-nx-main-600 text-nx-white",
                "text-nx-gray-600"
              )
              .build()}
            onClick={() => setMode(true)}
          >
            Tema Escuro
          </div>
        </div>
      </li>
      <li
        className="w-full h-8 px-2 text-start text-xs flex flex-col justify-center text-nx-danger-700 rounded transition
           hover:bg-nx-main-600 hover:text-nx-white
        "
        onClick={() => {
          home.logout(navigate);
        }}
      >
        Sair
      </li>
    </ul>
  );
});
