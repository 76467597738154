import styled from "styled-components";
import { IconArrowLeft } from "../../fonts/icons/components/IconArrowLeft";
import { Color } from "../../fonts/Colors";

interface INexusBackButtonProps {
  onClick?: () => void;
}

const Content = styled.div`
  padding: 10px;
  border: 0.5px solid ${Color.gray400};
  border-radius: 4px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  background-color: transparent;
  display: flex;
  flex-direction: column;
`;

export const NexusBackButton = ({ onClick }: INexusBackButtonProps) => {
  return (
    <Content onClick={onClick}>
      <IconArrowLeft color={Color.main500} />
    </Content>
  );
};
