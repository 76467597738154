import { MemberUserCell } from "./MemberUserCell";
import { MemberProjectCell } from "./MemberProjectCell";
import { MemberUserRuleDropdown } from "../MemberUserRuleDropdown";
import { MembersMoreIcon } from "../../MembersMoreIcon/MembersMoreIcon";
import { useMembersUserModal } from "../../MembersAddUserModal/MembersUserContext";
import { MembersModals } from "../../MembersModals/MembersModals";

export const MemberTableRow = () => {
  const { showModal } = useMembersUserModal();

  return (
    <tr className="border-b-[0.031rem] border-nx-gray-100 dark:border-nx-gray-500 relative">
      <td>
        <MemberUserCell />
      </td>
      <td>
        <div className="flex flex-row gap-2 py-4 items-center">
          <MemberProjectCell />
          <div
            className="text-caption font-medium cursor-pointer text-nx-main-600 dark:text-nx-main-500"
            onClick={() => {
              showModal(new MembersModals().projectOptions());
            }}
          >
            Ver todos...
          </div>
        </div>
      </td>
      <td>
        <div>
          <MemberUserRuleDropdown />
        </div>
      </td>
      <td>
        <MembersMoreIcon
          onClickInRemove={() => {
            showModal(new MembersModals().removeUser());
          }}
        />
      </td>
    </tr>
  );
};
