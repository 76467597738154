import { useContext, useEffect } from "react";
import { FontStyles } from "../../../../../../../common/fonts/FontStyles";
import { Icons } from "../../../../../../../common/fonts/icons/icons";
import ManagerImages from "../../../../../../../common/fonts/ManagerImages";
import { SubmitHandler, useForm } from "react-hook-form";
import { validateEmail } from "../../../../../../../common/forms/validators/ValidateEmail";
import { validatePassword } from "../../../../../../../common/forms/validators/ValidatePassword";
import { NxFormField } from "../../../../../../../common/components/TextFields/NxFormField";
import { VisualContext } from "../../../../../../../core/context/VisualContext";
import { CheckBoxField } from "../../../../../../../common/components/TextFields/CheckField";
import { useNavigate } from "react-router-dom";
import { CSName } from "web/common/utils/ClassName";
import { NxOutlinedButton } from "web/common/components/Buttons/NxOutlinedButton";
import { NxButton } from "web/common/components/Buttons/NxButton";
import CircularProgressBar from "web/common/components/ProgressBar/CircularProgressBar";
import { useLogin } from "../../hooks/UseLogin";

type ILoginInputs = {
  email: string;
  password: string;
};

export function LoginForm() {
  const { login, loginState } = useLogin();
  const { isDarkMode } = useContext(VisualContext);
  const navigate = useNavigate();
  let maintain = false;

  const {
    handleSubmit,
    register,
    formState: { errors },
    setError,
  } = useForm<ILoginInputs>();

 /* const getError = (error: FieldError| undefined) :  FieldError| undefined => {
    if(!error) return undefined;
    if (loginState.isError == true) {
      return {
        type: error.type,
        message: ""
      };
    }
    return error;
  };*/

  useEffect(() => {
    if (loginState.isSuccess) navigate("/loading");
    if (loginState.isError) {
      setError("email", {});
      setError("password", {});
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginState]);

  const onSubmit: SubmitHandler<ILoginInputs> = (data) => {
    login({
      email: data.email,
      password: data.password,
      maintainLogin: maintain,
    });
  };

  return (
    <div>
      <form
        className="flex flex-col items-streetch mt-14 ps-14 pe-14 py-14"
        onSubmit={handleSubmit(onSubmit)}
      >
        <img
          className="self-center mb-24"
          src={isDarkMode ? ManagerImages.darkNexus : ManagerImages.lightNexus}
          alt=""
          width={100}
        />

        <label
          className={CSName(FontStyles.title)
            .combine("text-nx-main-800 dark:text-nx-white")
            .build()}
        >
          Boas vindas
        </label>
        <label
          className={CSName(FontStyles.bodyRegular)
            .combine("text-nx-gray-200")
            .build()}
        >
          Insira suas credenciais para acessar sua conta
        </label>
        <div className="flex flex-col gap-4 mt-12">
          <NxFormField
            labelText="Email"
            prefix={Icons.message}
            register={register("email", { validate: validateEmail })}
            error={errors.email}
            disabled={loginState.isLoading}
          />
          <NxFormField
            labelText="Senha"
            type="password"
            prefix={Icons.password}
            register={register("password", { validate: validatePassword })}
            error={errors.password}
            disabled={loginState.isLoading}
          />
        </div>

        <div className="flex flex-row items-center my-4 justify-between">
          <div className="flex flex-row text-center items-center gap-2">
            <CheckBoxField
              className={"bg-nx-focus-light dark:bg-nx-focus-dark"}
              onClick={(c) => {
                maintain = c;
              }}
            />
            <label
              className={CSName(FontStyles.caption)
                .combine("text-nx-main-800 dark:text-nx-white")
                .build()}
            >
              Lembrar meu login
            </label>
          </div>

          <label
            className={CSName(FontStyles.bodyBold)
              .combine("text-nx-main-500 cursor-pointer")
              .build()}
          >
            Esqueci email ou senha
          </label>
        </div>
        {loginState.isError && (
          <label
            className={CSName(FontStyles.bodyRegular)
              .combine("text-nx-danger-600 cursor-pointer mb-1")
              .build()}
          >
            Email ou senha incorretos
          </label>
        )}
        <div className="h-14 flex flex-col">
          <NxButton>
            {loginState.isLoading ? (
              <CircularProgressBar
                className="stroke-nx-white"
                width={20}
                height={20}
              />
            ) : (
              <label
                className={CSName(FontStyles.bodyBold)
                  .combine("text-nx-white text-sm cursor-pointer")
                  .build()}
              >
                Acessar Nexus
              </label>
            )}
          </NxButton>
        </div>

        <div className="grid grid-cols-1">
          <label
            className={CSName(FontStyles.caption)
              .combine("text-xs text-nx-gray-500 dark:text-nx-white pt-12 pb-2")
              .build()}
          >
            Ou acesse via:
          </label>
          <NxOutlinedButton combineClass="w-fit place-self-center px-2.5">
            <img src={ManagerImages.logoGoogle} width={20} height={20} alt="" />
          </NxOutlinedButton>
          <label
            className={CSName(FontStyles.caption)
              .combine("text-xs text-nx-gray-500 dark:text-nx-white pt-6 pb-4")
              .build()}
          >
            Não possui uma conta?
          </label>
        </div>
        <NxOutlinedButton
          combineClass="px-16 py-3 text-center text-nx-main-500 text-xs cursor-pointer place-self-center"
          onClick={() => navigate("/auth/sign-up")}
        >
          Criar agora
        </NxOutlinedButton>
      </form>
    </div>
  );
}
