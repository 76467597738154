export const MembersTableHeaderRow = () => {
  return (
    <tr className="text-left text-caption text-nx-gray-200 mb-4">
      <th className="py-4 pl-6">NOME</th>
      <th>PROJETOS</th>
      <th>CARGO</th>
      <th></th>
    </tr>
  );
};
