import { CSName } from "web/common/utils/ClassName";
import { NxButtonProps } from "./interfaces/NxButtonProps";

export function NxButton({
  combineClass,
  children,
  onClick,
}: NxButtonProps): React.JSX.Element {
  return (
    <button
      className={CSName("py-5 bg-nx-main-600 rounded")
        .combine(combineClass)
        .build()}
      onClick={onClick}
    >
      {children}
    </button>
  );
}
