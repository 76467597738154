import React from "react";

type NxModalProps = {
  showModal?: boolean;
  children: React.ReactNode;
  onOutsideClick?(): void;
};

export function NxModal({
  showModal,
  children,
  onOutsideClick,
}: NxModalProps): React.ReactElement {
  const ref = React.useRef<HTMLDivElement>(null);
  return (
    <div>
      {showModal && (
        <div
          ref={ref}
          className="w-lvh h-lvh min-w-full bg-nx-black/60 fixed z-50 inset-x-0 inset-y-0 flex justify-center"
          onClick={(e) => {
            if (e.target === ref.current) {
              onOutsideClick && onOutsideClick();
            }
          }}
        >
          <div className="m-auto p-4 bg-nx-white dark:bg-nx-dark-1100 rounded-lg z-50">
            {children}
          </div>
        </div>
      )}
    </div>
  );
}
