import { createContext, useContext, useEffect, useState } from "react";
import { MembersAddUserModal } from "./MembersAddUserModal";
import { IMembersModals, MembersModals } from "../MembersModals/MembersModals";
import { MembersProjectOptionsModal } from "../MembersProjectOptionsModal/MembersProjectOptionsModal";
import { MembersRemoveUserModal } from "../MembersRemoveUserModal/MembersRemoveUserModal";

interface MembersUserModalContextProps {
  showModal(modal: IMembersModals): void;
  closeModal(modal: IMembersModals): void;
}
export const MembersUserModalContext =
  createContext<MembersUserModalContextProps>({
    closeModal: (modal: IMembersModals) => {},
    showModal: (modal: IMembersModals) => {},
  });

export const useMembersUserModal = () => useContext(MembersUserModalContext);

export const MemberUserModelProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [modal, setModal] = useState<IMembersModals>(
    new MembersModals().none()
  );
  useEffect(() => {
    setModal(new MembersModals().none());
  }, []);
  return (
    <MembersUserModalContext.Provider
      value={{
        showModal: (m) => {
          setModal(m);
        },
        closeModal: (m) => {
          setModal(m);
        },
      }}
    >
      <div className="w-full h-full">
        {children}
        <MembersAddUserModal isOpen={modal.showAddUser} />
        <MembersProjectOptionsModal isOpen={modal.showProjectOptions} />
        <MembersRemoveUserModal isOpen={modal.showRemoveUser} />
      </div>
    </MembersUserModalContext.Provider>
  );
};
