import { useState } from "react"
import { sessionUsecase } from "web/core/domain/usecase/SessionUsecase";

export const useSession = () => {
    const [state, setState] = useState<boolean>();
    return {
        state: state,
        session: async ()=>{
            const result = await sessionUsecase();
            if(result) setState(true);
            else setState(false);
        }
    }
}