import { OptionsEntity } from '../entities/OptionsEntity';
import { IMembersRepository } from '../repositories/MembersRepository';

interface IMembersUsecase {
    getProjectOptions():Promise<Array<OptionsEntity>> 
}

export class MembersUsecase implements IMembersUsecase {

    constructor(private repository: IMembersRepository){}
    async getProjectOptions(): Promise<Array<OptionsEntity>> {
        return await this.repository.getProjectOptions();
    }


}