import type * as CSS from "csstype";
import { Color } from "./Colors";

interface Style extends CSS.Properties, CSS.PropertiesHyphen {}

export class LabelStyles {
  static bodyBold: Style = {
    color: Color.main500,
    fontSize: "12px",
    fontWeight: 500,
    textAlign: "center",
  };

  static caption: Style = {
    color: Color.white,
    fontSize: "10px",
    fontWeight: 400,
    textAlign: "center",
  };
}

export class FontStyles {
  static title: string = "text-xl font-semibold text-center";

  static subtitle: string = "text-base font-semibold text-center";

  static bodyLargeBold: string = "text-sm font-medium text-center";

  static bodyRegular: string = "text-xs font-normal text-center";

  static bodyBold: string = "text-xs font-medium text-center";

  static caption: string = "text-[10px] font-normal text-center";
}
