import { useState } from "react";
import { PrimaryButton } from "web/common/components/Buttons/PrimaryButton";
import { SecondaryButton } from "web/common/components/Buttons/SecundayButton";
import { NxModal } from "web/common/components/NxModal/NxModal";
import { NxSVG } from "web/common/components/NxSvg";
import { FontStyles } from "web/common/fonts/FontStyles";
import { IconDelete } from "web/common/fonts/icons/components/IconDelete";
import { CSName } from "web/common/utils/ClassName";
import { useMembersUserModal } from "../MembersAddUserModal/MembersUserContext";
import { MembersModals } from "../MembersModals/MembersModals";
import {
  NxSnackbar,
  NxSnackbarType,
} from "web/common/components/NxSnackbar/NxSnackbar";

type Parameters = {
  isOpen?: boolean;
};

export function MembersRemoveUserModal({ isOpen }: Parameters) {
  const [showSnackabar, setSnackbar] = useState(false);
  const { closeModal } = useMembersUserModal();

  return (
    <div>
      {" "}
      <NxModal
        showModal={isOpen}
        onOutsideClick={() => {
          closeModal(new MembersModals().none());
        }}
      >
        <div className="grid grid-rows-1 pr-24 pb-6">
          <label
            className={CSName(FontStyles.subtitle)
              .combine("text-nx-dark-800 dark:text-nx-white text-start")
              .build()}
          >
            Acesso a projetos
          </label>
          <label
            className={CSName(FontStyles.caption)
              .combine("text-start text-nx-gray-400 dark:text-nx-gray-200")
              .build()}
          >
            Deseja realmente remover o acesso de Nome_Usuário ao Agrosmart
            Nexus?
          </label>
        </div>
        <div className="pb-2">
          <PrimaryButton
            className="w-full px-2 grid grid-rows-0.5 place-content-center py-3 bg-nx-main-600 rounded"
            onClick={() => {
              closeModal(new MembersModals().none());
            }}
          >
            <label
              className={CSName(FontStyles.bodyBold)
                .combine("cursor-pointer")
                .build()}
            >
              Manter membro
            </label>
          </PrimaryButton>
        </div>
        <SecondaryButton
          className="w-full px-2 py-3 grid grid-rows-0.5 place-content-center border-nx-danger-600"
          onClick={() => {
            closeModal(new MembersModals().none());
            setSnackbar(true);
          }}
        >
          <div className="flex justify-center">
            <label
              className={CSName(FontStyles.bodyBold)
                .combine("text-nx-danger-600 cursor-pointer pr-2")
                .build()}
            >
              Remover da organização
            </label>
            <NxSVG className="w-5 h-5 fill-nx-danger-600">
              <IconDelete />
            </NxSVG>
          </div>
        </SecondaryButton>
      </NxModal>
      <NxSnackbar
        type={NxSnackbarType.success}
        label="Membro removido com sucesso"
        showIcon={true}
        showSnackbar={showSnackabar}
        onSnackbarClose={() => {
          setSnackbar(false);
        }}
      />
    </div>
  );
}
