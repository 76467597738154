import React, { useContext } from "react";
import {
  Align,
  Container,
  Display,
} from "../../../../common/components/shapes";
import { VisualContext } from "../../../../core/context/VisualContext";
import { Color } from "../../../../common/fonts/Colors";
import ManagerImages from "../../../../common/fonts/ManagerImages";

export default function SplashPage() {
  const { isDarkMode } = useContext(VisualContext);

  return (
    <Container
      $background={isDarkMode ? Color.dark1100 : Color.white}
      $width={100}
      $height={100}
      $align={Align.center}
      $display={Display.row}
    >
      <Container
        $width={10}
        $height={10}
        $align={Align.center}
        $display={Display.column}
      >
        <img
          src={isDarkMode ? ManagerImages.darkNexus : ManagerImages.lightNexus}
          alt="logo"
        />
      </Container>
    </Container>
  );
}
