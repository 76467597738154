import { CSName } from "web/common/utils/ClassName";
import { NxButtonProps } from "./interfaces/NxButtonProps";

export function NxOutlinedButton({
  combineClass,
  children,
  onClick,
}: NxButtonProps): React.JSX.Element {
  return (
    <button
      className={CSName(
        "py-2.5 border-[0.5px] border-nx-gray-200 rounded w-fit"
      )
        .combine(combineClass)
        .build()}
      onClick={onClick}
    >
      {children}
    </button>
  );
}
