import { useForm } from "react-hook-form";
import { NxFormField } from "../../../../../../common/components/TextFields/NxFormField";
import { validateName } from "../../../../../../common/forms/validators/ValidateName";
import { NxDropFormField } from "../../../../../../common/components/TextFields/NxDropFormField";
import { useTranslation } from "react-i18next";
import { CategoryEntity } from "web/features/auth/domain/entities/CategoryEntity";

export type ISignUpStepTwoInputs = {
  companyName: string;
  category: string;
};

export interface ISignUpStepTwoProps {
  submitButtonRef: React.RefObject<HTMLButtonElement>;
  categories: CategoryEntity[];
  onSubmit: (values: ISignUpStepTwoInputs) => void;
}

export const SignUpStepTwoForm = ({
  submitButtonRef,
  categories,
  onSubmit,
}: ISignUpStepTwoProps) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<ISignUpStepTwoInputs>();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        paddingInline: 56,
      }}
    >
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 16,
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <NxFormField
          labelText={t("companyName")}
          register={register("companyName", { validate: validateName })}
          error={errors.companyName}
        />
        <NxDropFormField
          labelText={t("category")}
          register={register("category", {
            required: "Campo obrigatório",
          })}
          error={errors.category}
          options={categories}
          labelBuilder={(category) => category.name}
          valueBuilder={(category) => JSON.stringify(category)}
        ></NxDropFormField>
        <button ref={submitButtonRef} style={{ display: "none" }} />
      </form>
    </div>
  );
};
