import { useState } from "react";
import { NxSVG } from "web/common/components/NxSvg";
import { FontStyles } from "web/common/fonts/FontStyles";
import { IconMore } from "web/common/fonts/icons/components/IconMore";
import { IconRemove } from "web/common/fonts/icons/components/IconRemove";
import { CSName } from "web/common/utils/ClassName";

type Parameters = {
    onClickInRemove:()=>void
}

export function MembersMoreIcon({onClickInRemove}:Parameters) {
  const [visibility, setVisibility] = useState(false);
  return (
    <div>
      {visibility && (
        <div
          className="bg-nx-gray-50 dark:bg-nx-dark-1200 rounded px-2 py-2 absolute z-20 right-10 mt-8"
          onClick={() => {
            setVisibility(false);
            onClickInRemove();
          }}
        >
          <div className="w-36 grid grid-flow-col px-2 py-2 hover:bg-nx-main-600 rounded group">
            <NxSVG className="fill-nx-main-600 dark:fill-nx-white group-hover:fill-nx-main-500 w-5 h-5">
              <IconRemove />
            </NxSVG>
            <label
              className={
                CSName(FontStyles.bodyRegular).combine("pl-2 cursor-pointer text-black dark:text-white group-hover:text-white").build()
              }
            >
              Remover membro
            </label>
          </div>
        </div>
      )}
      <div
          className="cursor-pointer relative"
          onClick={() => {
            setVisibility(!visibility);
          }}
        >
          <NxSVG className="fill-nx-main-500 w-5 h-5">
            <IconMore />
          </NxSVG>
        </div>
    </div>
  );
}
