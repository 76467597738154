import { OptionsEntity, OptionsModel } from "../entities/OptionsEntity";

export interface IMembersRepository {
    getProjectOptions():Promise<Array<OptionsEntity>>,
}

export class MembersRepository implements IMembersRepository {
    async getProjectOptions(): Promise<Array<OptionsEntity>> {
        const options = [
            "Safra Milho Brasil",
            "Safra Milho Brasil",
            "Planejamento de logística",
            "Planejamento de logística",
            "Produção MT",
            "Produção MT",
            "Safra Milho Brasil",
            "Safra Milho Brasil",
            "Planejamento de logística",
            "Planejamento de logística",
            "Produção MT",
            "Produção MT",
          ];

        return options.map((element)=>{
            return new OptionsModel({ isSelected: false, name: element });
        });
    }

}
