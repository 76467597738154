export class VisualConfig {

    static changeMode(mode:boolean):void {
        localStorage.setItem("dark_mode", JSON.stringify(mode));
    }

    static isDarkMode():boolean {
        const isDark = localStorage.getItem('dark_mode') ?? JSON.stringify(true);
        return JSON.parse(isDark)
    }
}