import { CSName } from "../../utils/ClassName";
import CircularProgressBar from "../ProgressBar/CircularProgressBar";
import { useNxButton } from "./hooks/UseNxButton";
import { NxButtonProps } from "./interfaces/NxButtonProps";

export const NxFilledButton = ({
  children,
  isLoading,
  onClick,
  combineClass,
}: NxButtonProps) => {
  const { loadingState, clickWithLoading } = useNxButton(onClick);
  return (
    <button
      className={CSName(
        `bg-nx-main-600 py-2 w-full  text-xs font-medium text-nx-white rounded`
      )
        .combine(combineClass)
        .build()}
      onClick={clickWithLoading}
    >
      {loadingState || isLoading ? (
        <CircularProgressBar
          width={16}
          height={16}
          className="stroke-nx-white"
        />
      ) : (
        children
      )}
    </button>
  );
};
