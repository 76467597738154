import React from "react";

export const MemberProjectCell = () => {
  return (
    <div
      className="bg-nx-main-400/20 rounded-[50px] py-1.5 px-4 text-caption font-normal cursor-pointer
      text-nx-main-700 dark:text-nx-main-200"
    >
      Soja 2024
    </div>
  );
};
