import { NxSVG } from "../../../../common/components/NxSvg";
import { useNavigate } from "react-router-dom";
import { CSName } from "../../../../common/utils/ClassName";

interface HomeNavBarOptionProps {
  title: string;
  icon: JSX.Element;
  route: string;
  isSelected: boolean;
  isSvg?: boolean;
}

export const HomeNavBarOption = ({
  title,
  icon,
  route,
  isSelected,
  isSvg = true,
}: HomeNavBarOptionProps) => {
  const navigate = useNavigate();

  return (
    <div
      className={CSName(
        `group flex flex-row  min-h-8 mb-2 w-full
         px-6 gap-4 items-center hover:bg-nx-main-600/10 
         transition cursor-pointer`
      )
        .concatIf(isSelected, "bg-nx-main-600/10 ")
        .build()}
      onClick={() => navigate(route)}
    >
      <div className="flex flex-row items-center justify-center w-5 h-5">
        {isSvg ? (
          <NxSVG
            className={CSName("")
              .concatIf(isSelected, "fill-blue-500", "fill-gray-500")
              .build()}
            width={20}
            height={20}
          >
            {icon}
          </NxSVG>
        ) : (
          icon
        )}
      </div>
      <div
        className={CSName(
          "flex-1 text-xs font-medium truncate"
        )
          .concatIf(
            isSelected,
            "text-nx-main-600",
            `text-nx-gray-600 dark:text-nx-white`
          )
          .build()}
      >
        {title}
      </div>
    </div>
  );
};

export const HomeNavSectionTitle = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <div className="text-[0.625rem] text-nx-gray-400 font-semibold px-6 py-2 mb-2">
      {children}
    </div>
  );
};
