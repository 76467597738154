export const IconHelpFilled = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="fill"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM9.076 11.532C9.076 11.9031 9.37686 12.204 9.748 12.204H9.76906C10.1286 12.204 10.42 11.9126 10.42 11.5531C10.42 11.2691 10.6065 11.0229 10.8668 10.9094C11.1375 10.7914 11.3805 10.6509 11.596 10.488C11.932 10.232 12.188 9.932 12.364 9.588C12.54 9.244 12.628 8.872 12.628 8.472C12.628 8.12 12.556 7.796 12.412 7.5C12.276 7.196 12.08 6.932 11.824 6.708C11.576 6.484 11.28 6.312 10.936 6.192C10.6 6.064 10.232 6 9.832 6C9.36 6 8.928 6.088 8.536 6.264C8.144 6.44 7.812 6.684 7.54 6.996C7.42447 7.13253 7.32503 7.27826 7.24167 7.43317C7.06117 7.76865 7.29924 8.14804 7.66961 8.2372C8.0226 8.32218 8.36584 8.10305 8.59569 7.822C8.67616 7.72361 8.76826 7.63628 8.872 7.56C9.144 7.36 9.46 7.26 9.82 7.26C10.084 7.26 10.316 7.316 10.516 7.428C10.724 7.532 10.884 7.68 10.996 7.872C11.116 8.064 11.176 8.284 11.176 8.532C11.176 8.956 10.996 9.324 10.636 9.636C10.432 9.81215 10.1717 9.96547 9.85488 10.096C9.41085 10.2789 9.076 10.6819 9.076 11.1621V11.532ZM9.748 14.748C9.916 14.748 10.064 14.708 10.192 14.628C10.328 14.548 10.436 14.44 10.516 14.304C10.596 14.168 10.636 14.02 10.636 13.86C10.636 13.7 10.596 13.552 10.516 13.416C10.436 13.28 10.328 13.172 10.192 13.092C10.064 13.012 9.916 12.972 9.748 12.972C9.58 12.972 9.428 13.012 9.292 13.092C9.164 13.172 9.06 13.28 8.98 13.416C8.9 13.552 8.86 13.7 8.86 13.86C8.86 14.02 8.9 14.168 8.98 14.304C9.06 14.44 9.164 14.548 9.292 14.628C9.428 14.708 9.58 14.748 9.748 14.748Z"
      />
    </svg>
  );
};
