class CSNameBuilder extends String {
  concatIf(condition: boolean, isTrue: string, isElse?: string): CSNameBuilder {
    return new CSNameBuilder(
      condition
        ? this.concat(" " + isTrue)
        : isElse
        ? this.concat(" " + isElse)
        : this
    );
  }

  combine(value?: string): CSNameBuilder {
    if (!value) return this;
    return new CSNameBuilder(`${this} ${value}`);
  }

  matchFirst(conditions: [boolean, string][], orElse?: string): CSNameBuilder {
    const match = conditions.find((c) => c[0]);
    if (match) return new CSNameBuilder(this.concat(" " + match[1]));
    if (orElse) return new CSNameBuilder(this.concat(" " + orElse));
    return this;
  }

  build(): string {
    return this.toString();
  }
}

export const CSName = (str: string): CSNameBuilder => new CSNameBuilder(str);
