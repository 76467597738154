import { Navigate, RouteObject } from "react-router-dom";
import { HomeOutlet } from "../pages/HomeOutlet";
import { HomePage } from "../pages/HomePage";
import { MemberListPage } from "../../../members/presentation/pages/MemberListPage";

const HomeRoutes: RouteObject = {
  path: "/",
  element: <HomeOutlet />,
  children: [
    {
      index: true,
      element: <Navigate replace to="/home" />,
    },
    {
      path: "home",
      element: <HomePage />,
    },
    {
      path: "projects",
      element: <div>projects</div>,
    },
    {
      path: "projects/:id",
      element: <div>projects</div>,
    },
    {
      path: "members",
      element: <MemberListPage />,
    },
    {
      path: "settings",
      element: <div>settings</div>,
    },

    {
      path: "signatures",
      element: <div>signatures</div>,
    },
    {
      path: "support",
      element: <div>support</div>,
    },
  ],
};

export default HomeRoutes;
