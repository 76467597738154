import React, { ReactNode, useEffect, useState } from "react";
import CircularProgressBar from "../components/ProgressBar/CircularProgressBar";

interface BuilderComponents {
  children?: React.ReactNode;
  data?: any;
  emptyComponent?: React.ReactNode;
}

class DataBuilder extends React.Component<BuilderComponents> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(components: BuilderComponents) {
    super(components);
  }
  private isLoading(): React.ReactNode {
    return (
      <CircularProgressBar
        className="stroke-nx-main-500 dark:stroke-nx-white"
        width={20}
        height={20}
      />
    );
  }

  private isEmpty(): React.ReactNode {
    return this.props.emptyComponent ?? <label>Sem dados disponível</label>;
  }

  private isSuccessed(): React.ReactNode {
    return this.props.children ?? <div></div>;
  }

  render(): ReactNode {
    if (this.props.data === undefined) return this.isLoading();
    if (Object.keys(this.props.data).length === 0) return this.isEmpty();
    else return this.isSuccessed();
  }
}

type RenderElements = {
  promise: () => Promise<any>;
  successed: (data: any) => void;
  failed?: (data: any) => void;
  empty?: React.ReactNode;
  children: React.ReactNode;
};

export function DataRender(props: RenderElements) {
  const [data, setData] = useState();

  useEffect(() => {
    props
      .promise()
      .then((response) => {
        setTimeout(() => {
          props.successed(response);
          setData(response);
        }, 3000);
      })
      .catch((e) => {
        props.failed!(e);
      });
  }, [props, setData]);

  return (
    <DataBuilder data={data} emptyComponent={props.empty}>
      {props.children}
    </DataBuilder>
  );
}
