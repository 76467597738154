import { NxSVG } from "../../../../common/components/NxSvg";
import ManagerImages from "../../../../common/fonts/ManagerImages";
import { HomeCarrousel } from "../components/HomeCarrousel/HomeCarrousel";
import { NxFormField } from "../../../../common/components/TextFields/NxFormField";
import { IconSearch } from "../../../../common/fonts/icons/components/IconSearch";

import { HomeCardProject } from "../components/HomeCardProject";
import { NxAddActionButton } from "../../../../common/components/Buttons/NxAddActionButton";
import { NxPageText } from "../../../../common/components/Texts/NxPageText";

export const HomePage = () => {
  return (
    <div className="px-6 pt-8  flex flex-col w-full">
      <div className="flex flex-row w-full mb-6">
        <NxPageText> Boas vindas Nome_Usuário!</NxPageText>
        <NxAddActionButton label="Novo projeto" onClick={() => {}} />
      </div>
      <HomeCarrousel
        items={[
          {
            title: "Danos de geada na região Centro Oeste",
            font: "G1",
            date: "09/02/2024",
            image: ManagerImages.carrousel1,
          },
          {
            title: "Anomalias climáticas em Março",
            font: "Weather Channel",
            date: "09/02/2024",
            image: ManagerImages.carrousel2,
          },
          {
            title: "Aumento de produtividade em 2024",
            font: "G1",
            date: "09/02/2024",
            image: ManagerImages.carrousel3,
          },
          {
            title: "Danos de geada na região Centro Oeste",
            font: "G1",
            date: "09/02/2024",
            image: ManagerImages.carrousel1,
          },
          {
            title: "Anomalias climáticas em Março",
            font: "Weather Channel",
            date: "09/02/2024",
            image: ManagerImages.carrousel2,
          },
          {
            title: "Aumento de produtividade em 2024",
            font: "G1",
            date: "09/02/2024",
            image: ManagerImages.carrousel3,
          },
        ]}
      />
      <h3 className="text-base font-semibold mt-6 text-nx-gray-600 dark:text-nx-white">
        Acessados recentemente
      </h3>
      <div className="w-80">
        <NxFormField
          placeholder="Encontrar dashboard..."
          prefix={
            <NxSVG className="stroke-nx-gray-400">
              <IconSearch />
            </NxSVG>
          }
        />
      </div>

      <div className="flex flex-row gap-6 flex-wrap">
        <HomeCardProject
          project={{
            image:
              "https://i.pinimg.com/550x/d6/99/00/d69900c385273d6b431a6bedcf7d94f3.jpg",
            title: "Projeto 1",
            description: "Descrição do projeto 1",
          }}
          onProjectClick={() => {}}
        />
        <HomeCardProject onProjectClick={() => {}} />
        <HomeCardProject onProjectClick={() => {}} />
        <HomeCardProject onProjectClick={() => {}} />
        <HomeCardProject onProjectClick={() => {}} />
        <HomeCardProject onProjectClick={() => {}} />
        <HomeCardProject onProjectClick={() => {}} />
        <HomeCardProject onProjectClick={() => {}} />
      </div>
    </div>
  );
};
