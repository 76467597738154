export const IconUsers = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="fill"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 6.5C10 7.88333 8.88982 9 7.50417 9C6.11853 9 5 7.88333 5 6.5C5 5.11667 6.11853 4 7.50417 4C8.88982 4 10 5.11667 10 6.5ZM16 7.5C16 8.88 14.8878 10 13.505 10C12.1222 10 11 8.88 11 7.5C11 6.12 12.1222 5 13.505 5C14.8878 5 16 6.12 16 7.5ZM13.5 11C12.0027 11 9 11.736 9 13.2V14.2C9 14.64 9.36818 15 9.81818 15H17.1818C17.6318 15 18 14.64 18 14.2V13.2C18 11.736 14.9973 11 13.5 11ZM2 12.9167C2 10.975 5.98719 10 7.97652 10C8.54002 10 9.2572 10.0833 10 10.2333C8.25827 11.175 7.97652 12.4167 7.97652 13.125V15H2.85379C2.3842 15 2 14.625 2 14.1667V12.9167Z"
      />
    </svg>
  );
};
