import React from "react";
import { NxTextProps } from "./interfaces/NxTextProps";
import { CSName } from "../../utils/ClassName";

export const NxBodyLargeText = ({ children, className }: NxTextProps) => {
  return (
    <h1
      className={CSName(
        "text-sm flex-1 font-medium text-nx-gray-600 dark:text-nx-white"
      )
        .combine(className)
        .build()}
    >
      {children}
    </h1>
  );
};
