export interface IMembersModals {
  showAddUser: boolean;
  showRemoveUser: boolean;
  showProjectOptions: boolean;
}

export class MembersModals {
  modal: IMembersModals = {
    showAddUser: false,
    showRemoveUser: false,
    showProjectOptions: false,
  };

  none(): IMembersModals {
    this.modal = {
      showAddUser: false,
      showRemoveUser: false,
      showProjectOptions: false,
    };

    return this.modal;
  }

  addUser(): IMembersModals {
    this.modal = {
      showAddUser: true,
      showRemoveUser: false,
      showProjectOptions: false,
    };

    return this.modal;
  }

  removeUser(): IMembersModals {
    this.modal = {
      showAddUser: false,
      showRemoveUser: true,
      showProjectOptions: false,
    };

    return this.modal;
  }

  projectOptions(): IMembersModals {
    this.modal = {
      showAddUser: false,
      showRemoveUser: false,
      showProjectOptions: true,
    };

    return this.modal;
  }
}
