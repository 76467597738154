import styled from "styled-components";
import { Color } from "../../fonts/Colors";

export const SecondaryButton = styled.button<{ $width?: number }>`
  background: transparent;
  cursor: pointer;
  border-radius: 4px;
  font-size: 12px;
  border: 0.5px solid ${Color.lightDisabled};
  padding: 12px 24px 12px, 24px;
  ${(props) => props.$width && `width: ${props.$width}%`};
  height: 40px;
  color: ${Color.main500};
`;
