export const IconImage = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="fill"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.19995 7.99996C3.19995 5.34899 5.34898 3.19995 7.99995 3.19995H23.9999C26.6509 3.19995 28.8 5.34898 28.8 7.99995V23.9999C28.8 26.6509 26.6509 28.8 24 28.8H7.99996C5.34899 28.8 3.19995 26.6509 3.19995 24V7.99996ZM25.6 8.8C25.6 10.1255 24.5255 11.2 23.2 11.2C21.8745 11.2 20.8 10.1255 20.8 8.8C20.8 7.47452 21.8745 6.4 23.2 6.4C24.5255 6.4 25.6 7.47452 25.6 8.8ZM7.21044 23.9791L11.8325 14.7349C12.2388 13.9224 13.3926 13.9061 13.8216 14.7069L16.6873 20.0563C17.0937 20.8149 18.1682 20.8508 18.6243 20.1211L20.4183 17.2508C20.9064 16.4697 22.0762 16.579 22.4113 17.4368L25.0033 24.0725C25.2902 24.8069 24.7486 25.6 23.9601 25.6H8.2122C7.37961 25.6 6.8381 24.7238 7.21044 23.9791Z"
      />
    </svg>
  );
};
