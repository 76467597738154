export const IconRoundedAddFilled = () => {
  return (
    <svg viewBox="0 0 16 16" fill="fill" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM11.75 8C11.75 8.41421 11.4142 8.75 11 8.75H8.74997V11C8.74997 11.4142 8.41418 11.75 7.99997 11.75C7.58576 11.75 7.24997 11.4142 7.24997 11V8.75H5C4.58579 8.75 4.25 8.41421 4.25 8C4.25 7.58579 4.58579 7.25 5 7.25H7.24997V4.99999C7.24997 4.58577 7.58576 4.24999 7.99997 4.24999C8.41418 4.24999 8.74997 4.58577 8.74997 4.99999V7.25H11C11.4142 7.25 11.75 7.58579 11.75 8Z"
      />
    </svg>
  );
};
