export class CategoryEntity {
  id: string;
  name: string;
  createdAt: Date;
  updatedAt: Date;

  constructor({ id, name, createdAt, updatedAt }: Partial<CategoryEntity>) {
    this.id = id!;
    this.name = name!;
    this.createdAt = createdAt!;
    this.updatedAt = updatedAt!;
  }

  static fromJSON(json: any): CategoryEntity {
    return new CategoryEntity({
      id: json.id,
      name: json.name,
      createdAt: new Date(json.createdAt),
      updatedAt: new Date(json.updatedAt),
    });
  }
}
