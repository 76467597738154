import { NexusIconsProps } from "../interfaces/NexusIconsProps";

export const IconSearch = ({ color }: NexusIconsProps) => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2.5 18.5L6.5 14.5" strokeWidth="1.5" strokeLinecap="round" />
      <circle cx="11.5" cy="9.5" r="6.25" strokeWidth="1.5" />
    </svg>
  );
};
