import React from "react";

import { HomeNavBar } from "../components/HomeNavBar";
import { Outlet } from "react-router-dom";
import { SessionWrapper } from "web/common/components/SessionWrapper/SessionWrapper";

export function HomeOutlet() {
  return (
    <SessionWrapper>
      <div className="flex flex-row w-screen h-screen overflow-hidden">
        <HomeNavBar />
        <div className="w-full overflow-y-scroll bg-nx-white dark:bg-nx-dark-1100">
          <Outlet />
        </div>
      </div>
    </SessionWrapper>
  );
}
