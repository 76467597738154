export interface OptionsEntity {
    isSelected:boolean,
    name:string,
}

export class OptionsModel implements OptionsEntity {
    isSelected: boolean;
    name: string;
    
    constructor({isSelected, name}: OptionsEntity){
        this.isSelected = isSelected;
        this.name = name;
    }
}