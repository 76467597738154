import { useContext } from "react";
import { Color } from "../../../../common/fonts/Colors";
import { VisualContext } from "../../../../core/context/VisualContext";
import { LabelStyles } from "../../../../common/fonts/FontStyles";
import CircularProgressBar from "../../../../common/components/ProgressBar/CircularProgressBar";
import { useNavigate } from "react-router-dom";

export function LoadingPage() {
  const { isDarkMode } = useContext(VisualContext);
  const navigate = useNavigate();

  setTimeout(() => {
    navigate("/home");
  }, 3000);

  return (
    <div
      className={`w-screen h-lvh grid grid-cols-1 gap-4 place-content-center ${
        isDarkMode ? "bg-nx-dark-1100" : "bg-nx-white"
      }`}
    >
      <CircularProgressBar className="stroke-nx-main-600" />
      <label className="pb-16 pt-6 text-xs dark:text-nx-gray-300 text-nx-gray-600 text-center">
        Carregando dados da plataforma...
      </label>
      <div
        className={`place-self-center w-96 rounded grid grid-rows-0 place-content-center p-4 ${
          isDarkMode ? "bg-nx-dark-1200" : "bg-nx-gray-50"
        } `}
      >
        <label
          className="w-full"
          style={{
            ...LabelStyles.caption,
            color: isDarkMode ? Color.gray400 : Color.gray500,
          }}
        >
          Analise dados climáticos na plataforma para identificar padrões
          correlacionados à produtividade da cultura, obtendo insights para
          otimizar estratégias agrícolas.
        </label>
      </div>
    </div>
  );
}
