export class CredentialEntity{
  access_token: string;
  refresh_token: string;
  name: string;
  constructor({ access_token, refresh_token, name }: Partial<CredentialEntity>) {
    this.access_token = access_token!;
    this.refresh_token = refresh_token!;
    this.name = name!;
  }

  static fromJson(json: any): CredentialEntity {
    return new CredentialEntity({
      access_token: json["access_token"],
      refresh_token: json["refresh_token"],
      name: json["name"],
    });
  }
}
