import { RouteObject } from "react-router-dom";
import React from "react";
import SplashPage from "./web/features/splash/presentation/pages/SplashPage";
import { LoadingPage } from "./web/features/loading/presentation/pages/LoadingPage";
import HomeRoutes from "./web/features/home/presentation/routes/HomeRoutes";
import MemberRoutes from "./web/features/members/presentation/routes/MemberRoutes";
import AuthRoutes from "./web/features/auth/presentation/routes/AuthRoutes";

export const AppRoutes: RouteObject[] = [
  {
    path: "/splash",
    element: <SplashPage />,
  },

  {
    path: "/loading",
    element: <LoadingPage />,
  },

  HomeRoutes,
  MemberRoutes,
  AuthRoutes,
];
