import { CredentialEntity } from "../entities/CredentialEntity";

export interface AuthLocalRepository {
  hasLogged(): Promise<boolean>;
  getCredentials(): Promise<CredentialEntity>;
  clearLogged(): void;
}

export class AuthLocalRepositoryImpl implements AuthLocalRepository {
  async hasLogged(): Promise<boolean> {
    var logged =
      sessionStorage.getItem("credential") ??
      localStorage.getItem("credential");
    if (logged) return true;
    return false;
  }

  async getCredentials(): Promise<CredentialEntity> {
    var logged =
      sessionStorage.getItem("credential") ??
      localStorage.getItem("credential") ??
      JSON.stringify({});
    var credentials: CredentialEntity = JSON.parse(logged);
    return credentials;
  }

  clearLogged(): void {
    sessionStorage.clear();
    localStorage.removeItem("credential");
  }
}
