import { useForm } from "react-hook-form";
import { Color } from "../../../../../../common/fonts/Colors";

import { NxFormField } from "../../../../../../common/components/TextFields/NxFormField";

import IconPassword from "../../../../../../common/fonts/icons/components/IconPassword";
import { validatePassword } from "../../../../../../common/forms/validators/ValidatePassword";
import { validateName } from "../../../../../../common/forms/validators/ValidateName";
import { IconMessage } from "../../../../../../common/fonts/icons/components/IconMessage";
import { validateEmail } from "../../../../../../common/forms/validators/ValidateEmail";
import { useTranslation } from "react-i18next";

export type ISignUpStepOneInputs = {
  name: string;
  lastName: string;
  email: string;
  password: string;
};

export interface ISignUpStepOneProps {
  submitButtonRef: React.RefObject<HTMLButtonElement>;
  initialValue?: ISignUpStepOneInputs;
  onSubmit: (values: ISignUpStepOneInputs) => void;
}

export const SignUpPageComponentStepOne = ({
  onSubmit,
  submitButtonRef,
  initialValue,
}: ISignUpStepOneProps) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ISignUpStepOneInputs>({
    defaultValues: initialValue,
  });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        paddingInline: 56,
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 16,
            width: "100%",
            marginBottom: 16,
          }}
        >
          <NxFormField
            register={register("name", { validate: validateName })}
            labelText={t('firstName')}
            error={errors.name}
          />
          <NxFormField
            register={register("lastName", {
              validate: validateName,
            })}
            labelText={t('lastName')}
            error={errors.lastName}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 16,
            width: "100%",
            marginBottom: 48,
          }}
        >
          <NxFormField
            prefix={<IconMessage color={Color.gray400} />}
            labelText={t('email')}
            register={register("email", {
              validate: validateEmail,
            })}
            error={errors.email}
          />
          <NxFormField
            prefix={<IconPassword color={Color.gray400} />}
            labelText={t('password')}
            type="password"
            register={register("password", {
              validate: validatePassword,
            })}
            error={errors.password}
          />
        </div>
        <button style={{ display: "none" }} ref={submitButtonRef} />
      </form>
    </div>
  );
};
