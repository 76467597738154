import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Align,
  Container,
  Display,
} from "../../../../../../common/components/shapes";

import ManagerImages from "../../../../../../common/fonts/ManagerImages";

import PageView from "../../../../../../common/components/PageView";
import {
  ISignUpStepOneInputs,
  SignUpPageComponentStepOne,
} from "../components/SignUpStepOneForm";
import {
  SignUpStepTwoForm,
  ISignUpStepTwoInputs,
} from "../components/SignUpStepTwoForm";
import { useNavigate } from "react-router-dom";
import { useVisualContext } from "../../../../../../core/context/VisualContext";
import { NexusBackButton } from "../../../../../../common/components/Buttons/NexusBackButton";
import { useCreateAccountHook } from "../hooks/UseCreateAccountHook";
import { NxFilledButton } from "web/common/components/Buttons/NxFilledButton";
import { useTranslation } from "react-i18next";
import { useCreateOrgHook } from "../hooks/UseCreateOrgHook";
import { CategoryEntity } from "../../../../domain/entities/CategoryEntity";

import { CSName } from "web/common/utils/ClassName";
import {
  NxSnackbarProvider,
  useNxSnackBarContext,
} from "web/common/providers/NxSnackBarProvider";
import { NxSnackbarType } from "web/common/components/NxSnackbar/NxSnackbar";

type StepIndicatorProps = {
  active: boolean;
};

export const StepIndicator = ({ active }: StepIndicatorProps) => {
  return (
    <div
      className={CSName(`h-1.5 rounded-full  transition-all duration-300`)
        .concatIf(active, `bg-nx-main-600 w-4`, `bg-gray-400 w-1.5`)
        .build()}
    ></div>
  );
};
export const SignUpPage = () => {
  return (
    <NxSnackbarProvider>
      <SignUpBody />
    </NxSnackbarProvider>
  );
};

export const SignUpBody = () => {
  const { t } = useTranslation();
  const { createAccount, createAccountState } = useCreateAccountHook();
  const { createOrg, orgState } = useCreateOrgHook();
  const { showSnackbar } = useNxSnackBarContext();
  const [categories, setCategories] = useState<CategoryEntity[]>([]);

  const { isDarkMode } = useVisualContext();
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const previousRef = useRef(0);

  const memoCurrentPageIndex = useMemo(
    () => currentPageIndex,
    [currentPageIndex]
  );

  const submitButtonOneRef = useRef<HTMLButtonElement>(null);
  const submitButtonTwoRef = useRef<HTMLButtonElement>(null);
  const stepOneFieldRef = useRef<ISignUpStepOneInputs>();
  const stepTwoFieldRef = useRef<ISignUpStepTwoInputs>();

  const navigate = useNavigate();

  useEffect(() => {
    previousRef.current = currentPageIndex;
  }, [currentPageIndex]);

  useEffect(() => {
    if (createAccountState.data) {
      setCategories(createAccountState.data);
      handlePageChange(1);
    }
    if (createAccountState.error) {
      showSnackbar({
        type: NxSnackbarType.error,
        label: t("CreateAccountFailureMessage"),
      });
    }
  }, [navigate, createAccountState, showSnackbar, t]);

  useEffect(() => {
    if (orgState.isSuccess) {
      navigate("/loading");
    }
    if (orgState.isError) {
      showSnackbar({
        type: NxSnackbarType.error,
        label: t("CreateOrgFailureMessage"),
      });
    }
  }, [navigate, orgState, showSnackbar, t]);

  const handlePageChange = (index: number) => setCurrentPageIndex(index);

  const handleAdvance = async () => {
    if (currentPageIndex === 0) submitButtonOneRef.current?.click();
    if (currentPageIndex === 1) submitButtonTwoRef.current?.click();
  };

  const onSubmitStepOne = (value: ISignUpStepOneInputs) => {
    stepOneFieldRef.current = value;
    createAccount({
      email: stepOneFieldRef.current!.email,
      firstName: stepOneFieldRef.current!.name,
      lastName: stepOneFieldRef.current!.lastName,
      password: stepOneFieldRef.current!.password,
    });
  };

  const onSubmitStepTwo = useCallback(
    (value: ISignUpStepTwoInputs) => {
      stepTwoFieldRef.current = value;
      const category = CategoryEntity.fromJSON(
        JSON.parse(stepTwoFieldRef.current!.category)
      );
      createOrg({
        name: stepTwoFieldRef.current!.companyName,
        category: category.id,
      });
    },
    [createOrg]
  );

  return (
    <div className="flex flex-col w-full h-full pb-6">
      <Container
        $width={100}
        $maring_vertical={40}
        $maring_horizontal={20}
        $align={Align.center}
        $display={Display.row}
      >
        <img
          src={isDarkMode ? ManagerImages.darkNexus : ManagerImages.lightNexus}
          alt=""
          width={100}
        />
      </Container>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          paddingLeft: 56,
        }}
      >
        <NexusBackButton
          onClick={() => {
            if (currentPageIndex > 0) {
              return handlePageChange(currentPageIndex - 1);
            }
            return navigate("/auth/login");
          }}
        />
      </div>
      <Container
        $width={100}
        $maring_vertical={24}
        $align={Align.center}
        $display={Display.column}
      >
        <label className="text-xl dark:text-nx-white text-nx-dark-800 font-semibold text-center">
          {t("createAccountTitle")}
        </label>
        <label className="text-xs dark:text-nx-gray-300 text-nx-gray-600 font-normal text-center">
          {t("createAccountDesc")}
        </label>
      </Container>
      <div
        style={{
          height: 300,
        }}
      >
        <PageView
          previousPageIndex={previousRef.current}
          currentPageIndex={memoCurrentPageIndex}
          pages={[
            <SignUpPageComponentStepOne
              initialValue={stepOneFieldRef.current}
              submitButtonRef={submitButtonOneRef}
              onSubmit={onSubmitStepOne}
            />,
            <SignUpStepTwoForm
              submitButtonRef={submitButtonTwoRef}
              onSubmit={onSubmitStepTwo}
              categories={categories}
            />,
          ]}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignSelf: "center",
          gap: 4,
          marginBottom: 8,
        }}
      >
        <StepIndicator active={memoCurrentPageIndex === 0} />
        <StepIndicator active={memoCurrentPageIndex === 1} />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          paddingInline: 56,
        }}
      >
        <NxFilledButton
          combineClass="h-[2.5rem]"
          isLoading={createAccountState.isLoading || orgState.isLoading}
          onClick={handleAdvance}
        >
          {memoCurrentPageIndex === 0 ? t("next") : t("accessNexus")}
        </NxFilledButton>
      </div>
    </div>
  );
};
