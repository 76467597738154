import { useReducer } from "react";
import { createAccountUsecase } from "web/features/auth/domain/usecase/CreateAccountUsecase";
import { CreateAccountParams } from "../../../../domain/params/CreateAccountParams";
import { CategoryEntity } from "web/features/auth/domain/entities/CategoryEntity";
import { CreateAccountFailure } from "web/features/auth/domain/errors/CreateAccountFailure";

type CreateAccountAction =
  | { type: "loading" }
  | { type: "success"; data: CategoryEntity[] }
  | { type: "error"; error: any };

interface CreateAccountState {
  isLoading: boolean;
  data?: CategoryEntity[];
  error?: any;
}

function reducer(state: CreateAccountState, action: CreateAccountAction) {
  switch (action.type) {
    case "loading":
      return { isLoading: true };
    case "success":
      return { isLoading: false, data: action.data };
    case "error":
      return { isLoading: false, error: action.error };
  }
}

export const useCreateAccountHook = () => {
  const [createAccountState, dispatch] = useReducer(reducer, {
    isLoading: false,
  });
  return {
    createAccountState,
    createAccount: async (params: CreateAccountParams) => {
      dispatch({ type: "loading" });
      const result = await createAccountUsecase(params);
      if (result instanceof CreateAccountFailure) {
        return dispatch({ type: "error", error: result });
      }
      return dispatch({ type: "success", data: result as CategoryEntity[] });
    },
  };
};
