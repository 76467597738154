import React, { ReactNode, useEffect, useRef, useState } from "react";
import { LabelStyles } from "../../fonts/FontStyles";
import { GlobalError, UseFormRegisterReturn } from "react-hook-form/dist/types";
import { IconDropButton } from "../../fonts/icons/components/IconDropButton";
import { Color } from "../../fonts/Colors";
import { NxSVG } from "../NxSvg";
import { CSName } from "../../utils/ClassName";

export interface INxFormFieldProps<T> {
  labelText?: string;
  prefix?: JSX.Element;
  register?: UseFormRegisterReturn;
  error?: GlobalError;
  options: T[];
  placeholder?: string;
  combineFormClass?: string;
  combineOptionsClass?: string;
  labelBuilder: (opt: T) => ReactNode;
  valueBuilder: (opt: T) => string;
}

interface DropdownValueProps<T> {
  placeholder?: string;
  dropValue: string | null;
  options: T[];
  labelBuilder: (opt: T) => ReactNode;
  valueBuilder: (opt: T) => string;
}

function DropdownValue<T>({
  dropValue,
  labelBuilder,
  valueBuilder,
  options,
  placeholder,
}: DropdownValueProps<T>) {
  const optionsFinded = options.find((opt) => valueBuilder(opt) === dropValue);

  return (
    <div className="grow dark:text-nx-white text-xs w-full line-clamp-1">
      {optionsFinded ? (
        labelBuilder(optionsFinded)
      ) : (
        <span className="text-nx-gray-200 dark:text-nx-gray-500">
          {placeholder}
        </span>
      )}
    </div>
  );
}

export function NxDropFormField<T>({
  prefix,
  labelText,
  register,
  error,
  options,
  placeholder,
  labelBuilder,
  valueBuilder,
  combineFormClass,
  combineOptionsClass,
}: INxFormFieldProps<T>) {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const [dropValue, setDropValue] = useState<string | null>(null);

  const hasError = (): boolean => {
    return error !== undefined;
  };

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      let parent = e.target as Node;
      while (parent) {
        parent = parent.parentNode as Node;
        if (parent === ref.current) return;
      }
      setIsOpen(false);
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={ref}
      onClick={() => setIsOpen(!isOpen)}
      className="relative flex flex-col overflow-visible w-full"
    >
      <label
        className={CSName("text-caption font-normal transition mb-1 text-left")
          .matchFirst(
            [
              [hasError(), "text-nx-danger-600"],
              [isOpen, "text-nx-main-600"],
            ],
            "dark:text-nx-white text-nx-gray-600"
          )
          .build()}
      >
        {labelText}
      </label>
      <div
        className={CSName(`flex flex-row gap-2  items-center h-10 py-3 px-4 rounded
        bg-nx-main-100/10 border-nx-gray-400 border-[0.5px]`)
          .matchFirst([
            [hasError(), "border-nx-danger-600  border-[1px]"],
            [
              isOpen,
              "border-nx-main-600 bg-nx-focus-light dark:bg-nx-focus-dark",
            ],
          ])
          .combine(combineFormClass)
          .build()}
      >
        {prefix && (
          <div
            style={{
              width: 20,
              height: 20,
            }}
          >
            {prefix}
          </div>
        )}
        <div className="dark:text-nx-white text-xs w-full">
          <DropdownValue
            placeholder={placeholder}
            dropValue={dropValue}
            options={options}
            labelBuilder={labelBuilder}
            valueBuilder={valueBuilder}
          />
        </div>
        <NxSVG
          className={CSName(`w-2 h-2`)
            .matchFirst(
              [
                [isOpen, " fill-nx-main-600"],
                [hasError(), "fill-nx-danger-600"],
              ],
              "fill-nx-gray-400"
            )

            .build()}
        >
          <IconDropButton />
        </NxSVG>
      </div>

      {error && (
        <span
          style={{
            ...LabelStyles.caption,
            marginTop: 4,
            color: Color.danger600,
            textAlign: "left",
          }}
        >
          {error.message}
        </span>
      )}

      {isOpen && (
        <div
          className={CSName(
            "absolute  w-full flex flex-col gap-2 top-[101%] p-2 shadow-md rounded max-h-44 overflow-y-auto dark:bg-nx-dark-1200 bg-nx-white"
          )
            .combine(combineFormClass)
            .build()}
        >
          {options.map((item, index) => (
            <div
              key={index}
              className={CSName(`text-xs p-2 rounded  font-normal cursor-pointer transition
            text-nx-gray-700 dark:text-nx-white hover:bg-nx-main-600 hover:text-nx-white`)
                .concatIf(
                  valueBuilder(item) === dropValue,
                  "bg-nx-main-600 text-nx-white",
                  ""
                )
                .build()}
              onClick={() => {
                register?.onChange({
                  type: "change",
                  target: {
                    name: register?.name,
                    value: valueBuilder(item),
                  },
                });
                setDropValue(valueBuilder(item));
              }}
            >
              {labelBuilder(item)}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
