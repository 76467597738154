import { NexusIconsProps } from "../interfaces/NexusIconsProps";

export const IconCheck = ({ color }: NexusIconsProps) => (
  <svg
    viewBox="0 0 16 16"
    fill="fill"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      stroke={color}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99998 14.4C11.5346 14.4 14.4 11.5346 14.4 8.00001C14.4 4.46538 11.5346 1.60001 7.99998 1.60001C4.46535 1.60001 1.59998 4.46538 1.59998 8.00001C1.59998 11.5346 4.46535 14.4 7.99998 14.4ZM11.7666 6.12879C12.0586 5.81588 12.0417 5.32547 11.7288 5.03343C11.4159 4.74138 10.9255 4.75829 10.6334 5.0712L6.72 9.26416L5.36656 7.81406C5.07452 7.50115 4.58411 7.48424 4.2712 7.77628C3.95829 8.06833 3.94138 8.55874 4.23343 8.87165L6.15343 10.9288C6.3 11.0858 6.50518 11.175 6.72 11.175C6.93481 11.175 7.13999 11.0858 7.28656 10.9288L11.7666 6.12879Z"
      fill="white"
    />
  </svg>
);
