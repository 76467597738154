export const IconRoundedAddOutlined = () => {
  return (
    <svg viewBox="0 0 16 17" fill="fill" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.75 8.5C11.75 8.91421 11.4142 9.25 11 9.25H8.75V11.5C8.75 11.9142 8.41421 12.25 8 12.25C7.58579 12.25 7.25 11.9142 7.25 11.5V9.25H5C4.58579 9.25 4.25 8.91421 4.25 8.5C4.25 8.08579 4.58579 7.75 5 7.75H7.25V5.5C7.25 5.08579 7.58579 4.75 8 4.75C8.41421 4.75 8.75 5.08579 8.75 5.5V7.75H11C11.4142 7.75 11.75 8.08579 11.75 8.5Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 16.5C12.4183 16.5 16 12.9183 16 8.5C16 4.08172 12.4183 0.5 8 0.5C3.58172 0.5 0 4.08172 0 8.5C0 12.9183 3.58172 16.5 8 16.5ZM8 15C11.5899 15 14.5 12.0899 14.5 8.5C14.5 4.91015 11.5899 2 8 2C4.41015 2 1.5 4.91015 1.5 8.5C1.5 12.0899 4.41015 15 8 15Z"
      />
    </svg>
  );
};
