export const validateName = (value: string): string | boolean => {
  if (!value) {
    return "Campo obrigatório";
  } else if (value.length <= 3) {
    return "O campo foi preenchido corretamente?";
  } else if (/\d/.test(value)) {
    return "O campo foi preenchido corretamente?";
  }
  return true;
};
