import { useState } from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import { NxFormField } from "web/common/components/TextFields/NxFormField";
import { CSName } from "web/common/utils/ClassName";

interface MembersEmailFieldProps {
  register: UseFormRegisterReturn;
  onSuggestionClicked: (suggestion: string) => void;
}

export const MembersEmailField = ({
  register,
  onSuggestionClicked,
}: MembersEmailFieldProps) => {
  const [suggestion, setSuggestion] = useState<string>("");
  const [isOpen, setIsOpen] = useState(false);

  function computedSuggestion(): string | undefined {
    const value = suggestion.trim();
    if (value.length) {
      if (value.includes("@")) return value;
      return `${value}@agrosmart.com.br`;
    }
  }

  return (
    <div className="relative ">
      <NxFormField
        labelText="Email"
        placeholder="Insira o email"
        register={register}
        onChange={(e) => {
          setSuggestion(e.target.value);
          setIsOpen(e.target.value.trim().length > 0);
        }}
      />
      <div
        className={CSName(`group absolute flex flex-col top-[110%] w-full cursor-pointer drop-shadow-lg
        bg-nx-gray-50 dark:bg-nx-dark-1200 p-2 rounded dark:border-nx-gray-400 border-nx-gray-200 border-[0.5px]
       `)
          .concatIf(isOpen, "visible", "hidden")
          .build()}
        onClick={() => {
          onSuggestionClicked(computedSuggestion() ?? "");
          setIsOpen(false);
        }}
      >
        <div className="flex flex-col gap p-2 rounded hover:bg-nx-main-600 transition">
          <h5 className="line-clamp-1 text-xs dark:text-nx-white group-hover:text-nx-white">
            {computedSuggestion()}
          </h5>
          <h6 className="text-caption text-nx-main-500  dark:text-nx-main-200 group-hover:text-nx-main-200">
            Selecionar email
          </h6>
        </div>
      </div>
    </div>
  );
};
