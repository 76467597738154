import React from "react";
import styled, { keyframes } from "styled-components";

interface PageViewProps {
  pages: JSX.Element[];
  currentPageIndex: number;
  previousPageIndex?: number;
}

interface FadeInWithSlideProps {
  $direction: "left" | "right";
}

const fadeInWithSlide = ({ $direction }: FadeInWithSlideProps) => keyframes`
  from {
    opacity: 0;
    transform:${
      $direction === "right" ? "translateX(-100%)" : "translateX(100%)"
    };
  }
  to {
    opacity: 1;
  }
`;

const PageViewContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Page = styled.div<FadeInWithSlideProps>`
  width: 100%;
  height: 100%;
  animation: ${(props) => fadeInWithSlide(props)} 0.5s ease;
`;

const PageView: React.FC<PageViewProps> = ({
  pages,
  currentPageIndex,
  previousPageIndex,
}) => {
  function getDirection(): "left" | "right" {
    if (previousPageIndex === undefined) return "left";
    return currentPageIndex > previousPageIndex ? "left" : "right";
  }

  return (
    <PageViewContainer>
      <Page $direction={getDirection()} key={currentPageIndex}>
        {pages[currentPageIndex]}
      </Page>
    </PageViewContainer>
  );
};

export default PageView;
