import { useEffect, useState } from "react";
import { FontStyles } from "../../../../../../common/fonts/FontStyles";
import {
  OptionsEntity,
} from "../../../../domain/entities/OptionsEntity";
import { DataRender } from "../../../../../../common/utils/DataBuilder";
import { MembersRepository } from "../../../../domain/repositories/MembersRepository";
import { MembersUsecase } from "../../../../domain/usecase/MembersUsecase";
import { CheckBoxField } from "web/common/components/TextFields/CheckField";
import { CSName } from "web/common/utils/ClassName";

interface IOptionsListElements {
  options?: OptionsEntity[];
  isAllSelected?: boolean;
}

class OptionsListElements implements IOptionsListElements {
  options?: OptionsEntity[];
  isAllSelected?: boolean;

  constructor({
    options,
    isAllSelected = false,
  }: IOptionsListElements) {
    this.isAllSelected = isAllSelected;
    this.options = options;
  }
}

type OptionsListParameters = {
  onUpdate: (options: OptionsEntity[]) => void;
}

export function OptionsList({ onUpdate }: OptionsListParameters) {
  const usecase = new MembersUsecase(new MembersRepository());
  const [state, setState] = useState<IOptionsListElements>(
    new OptionsListElements({
    })
  );
  
  useEffect(()=>{
    onUpdate((state.options ?? []).filter((e, index)=> e.isSelected));
  }, [onUpdate, state]);

  return (
    <div className="pb-7">
      <DataRender
        promise={() => usecase.getProjectOptions()}
        successed={(data) => {
          setState(
            new OptionsListElements({
              options: data,
              isAllSelected: state.isAllSelected,
            })
          );
        }}
      >
        <div className="flex pb-7">
          <CheckBoxField
            className="bg-nx-focus-light dark:bg-nx-focus-dark"
            onClick={(isClicked) => {
              state.options?.map((e) => {
                e.isSelected = isClicked;
                return e;
              });
              setState(
                new OptionsListElements({
                  options: state.options,
                  isAllSelected: isClicked,
                })
              );
            }}
          />
          <label
            className={CSName(FontStyles.bodyRegular).combine("pl-2 text-nx-gray-700 dark:text-nx-white text-center").build()}
          >
            {state.isAllSelected ? "Desmarcar todos" : "Marcar todos"}
          </label>
        </div>
        <div className="grid grid-cols-2 gap-4">
          {state.options?.map((e, index) => {
            return (
              <div className="flex">
                <CheckBoxField
                  className="bg-nx-focus-light dark:bg-nx-focus-dark"
                  isChecked={e.isSelected}
                  onClick={(c) => {
                    state.options![index].isSelected = !e.isSelected;
                    setState(
                      new OptionsListElements({
                        options: state.options,
                        isAllSelected: state.isAllSelected,
                      })
                    );
                  }}
                />
                <label
                  className={CSName(FontStyles.bodyRegular).combine("pl-2 text-nx-gray-700 dark:text-nx-white text-center").build()}
                >
                  {e.name}
                </label>
              </div>
            );
          })}
        </div>
      </DataRender>
    </div>
  );
}
