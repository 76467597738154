import { CredentialEntity } from "web/features/auth/domain/entities/CredentialEntity";
import { AuthLocalRepository } from "../../../auth/domain/repositories/AuthLocalRepository";

type Injectors ={
    authLocalRepository: AuthLocalRepository;
} 

interface IHomeUsecase {
    getUser(): Promise<CredentialEntity>,
    logout(redirect: Function): void,
}

export class HomeUsecase implements IHomeUsecase {
    private authLocalRepository: AuthLocalRepository;

    constructor({ authLocalRepository}: Injectors){
        this.authLocalRepository = authLocalRepository;
    }

    async getUser(): Promise<CredentialEntity> {
        return await this.authLocalRepository.getCredentials();
    }

    logout(redirect: Function): void {
        this.authLocalRepository.clearLogged();
        redirect("/auth/login");
    }
}