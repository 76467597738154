import { NxBodyLargeText } from "../../../../common/components/Texts/NxBodyLargeText";
import { NxPageText } from "../../../../common/components/Texts/NxPageText";
import { NxAddActionButton } from "../../../../common/components/Buttons/NxAddActionButton";
import { MembersTable } from "../components/MembersTable/MembersTable";
import { MemberUserModelProvider, useMembersUserModal } from "../components/MembersAddUserModal/MembersUserContext";
import { MembersModals } from "../components/MembersModals/MembersModals";

export const MemberListPage = () => {
  return (
    <MemberUserModelProvider>
      <MemberListBody />
    </MemberUserModelProvider>
  );
};
const MemberListBody = () => {
  const { showModal } = useMembersUserModal();
  return (
    <div className="flex flex-col">
      <div className="flex flex-col px-6 pt-8">
        <div className="flex flex-row w-full">
          <NxPageText>Gerenciar membros</NxPageText>
          <NxAddActionButton label="Novo projeto" onClick={()=>{showModal(new MembersModals().addUser());}} />
        </div>
        <NxBodyLargeText className="mt-8">
          Membros desta organização
        </NxBodyLargeText>
      </div>
      <MembersTable />
    </div>
  );
};
