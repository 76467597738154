import React, { useEffect, useState } from "react";
import { CSName } from "../../utils/ClassName";
import { NxSVG } from "../NxSvg";
import { LabelStyles } from "../../fonts/FontStyles";
import { IconError } from "../../fonts/icons/components/IconError";
import { IconInfo } from "../../fonts/icons/components/IconInfo";
import { IconWarning } from "../../fonts/icons/components/IconWarning";
import { IconCheck } from "../../fonts/icons/components/IconCheck";
import { AnimationElements } from "./interfaces/AnimationElements";

export enum NxSnackbarType {
  error = "bg-nx-danger-600",
  info = "bg-nx-gray-500",
  warning = "bg-nx-warning-600",
  success = "bg-nx-success-600",
}

export type NxSnackbarElements = {
  type?: NxSnackbarType;
  label: string;
  showIcon?: boolean;
  showSnackbar: boolean;
  onSnackbarClose?: () => void;
};

export function NxSnackbar({
  type,
  label,
  showIcon = false,
  showSnackbar = false,
  onSnackbarClose = () => {},
}: NxSnackbarElements): React.JSX.Element {
  const [element, setElements] = useState(
    new AnimationElements({ visible: true, classChange: "-bottom-14" })
  );

  const setAbovePx: any = () => {
    setTimeout(() => {
      setElements(
        new AnimationElements({
          visible: element.visible,
          classChange: "bottom-6",
        })
      );
    }, 100);
  };

  const setBelowPx: any = () => {
    if (element.visible) {
      setElements(
        new AnimationElements({
          visible: element.visible,
          classChange: "-bottom-14",
        })
      );
      setTimeout(() => {
        setElements(
          new AnimationElements({
            visible: false,
            classChange: element.classChange,
          })
        );
        onSnackbarClose();
      }, 500);
    }
  };

  const getIcon: any = () => {
    switch (type) {
      case NxSnackbarType.error:
        return <IconError />;
      case NxSnackbarType.warning:
        return <IconWarning />;
      case NxSnackbarType.info:
        return <IconInfo />;
      default:
        return <IconCheck />;
    }
  };

  useEffect(() => {
    setElements(new AnimationElements({ visible: true, classChange: "-bottom-14" }));
    if (showSnackbar) {
      setAbovePx();
      setTimeout(() => {
        setBelowPx();
      }, 3000);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSnackbar]);

  return (
    <div>
      {element.visible && (
        <div
          className={CSName(
            "w-screen fixed z-50 inset-x-0 active:transition duration-700"
          )
            .combine(element.classChange)
            .build()}
          onClick={() => {
            setBelowPx();
          }}
        >
          <div
            className={CSName(
              "w-fit m-auto px-2 py-2.5 rounded text-nx-white grid grid-flow-col"
            )
              .combine(type ?? "bg-nx-success-600")
              .build()}
          >
            {showIcon && (
              <NxSVG width={16} height={16} className="mr-2">
                {getIcon()}
              </NxSVG>
            )}
            <label className="cursor-pointer" style={LabelStyles.caption}>
              {label}
            </label>
          </div>
        </div>
      )}
    </div>
  );
}
