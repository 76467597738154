import { IconRoundedAddFilled } from "../../fonts/icons/components/IconRoundedAddFilled";
import { NxSVG } from "../NxSvg";

interface NxAddActionButtonProps {
  label: string;
  onClick: () => void;
}

export const NxAddActionButton = ({
  label,
  onClick,
}: NxAddActionButtonProps) => {
  return (
    <button
      className="h-8 flex px-3 items-center flex-row gap-2 text-xs rounded text-nx-white font-medium bg-nx-main-600 hover:bg-nx-main-500"
      onClick={onClick}
    >
      {label}
      <NxSVG className="fill-nx-white w-4 h-4">
        <IconRoundedAddFilled />
      </NxSVG>
    </button>
  );
};
